import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import cities from "../city.json";
import axios from "axios";
import { useForm } from "react-hook-form";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import CustomButton from "./CustomButton";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function PaymentModal({
  setCurrentLevel,
  setCurrentPacket,
  setDiscountAmount,
  setOpenModal,
  openModal,
  packetInfos,
  discount,
  handleBuy,
}) {
  const [step, setStep] = useState(1);
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [sendCode, setSendCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isPhoneSendCodeLoading, setIsPhoneSendCodeLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Yeni Kullanıcı Kontrol
  const [isNewUser, setIsNewUser] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [isVisiblePassword, setIsVisiblePassword] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      name: "",
      surname: "",
      email: "destek@tovivu.com",
      address: "",
      city: "",
      idNumber: "",
      phoneNumber: "",
      password: "",
      discountCode: "",
      verifyCode: "",
    },
  });

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const formatPhoneNumber = (value) => {
    const cleanedValue = value.replace(/\D/g, "");
    const match = cleanedValue.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
    }
    return cleanedValue;
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value.replace(/\D/g, "").slice(0, 10);
    setValue("phoneNumber", formatPhoneNumber(input));
  };

  // Kodu Doğrula
  const checkVerifyCode = async () => {
    const rawPhoneNumber = watch("phoneNumber").replace(/\s/g, "");
    const verifyCode = watch("verifyCode");

    if (!verifyCode) {
      setShowErr(true);
      setErr("Lütfen doğrulama kodunu giriniz.");
      return;
    }

    try {
      setIsLoading(true);

      const response = await axios.get(
        `${backendUrl}/tel-verify?phone_number=${rawPhoneNumber}&verifcation_code=${verifyCode}`
      );
      setIsLoading(false);

      if (response.data.data.status) {
        setIsPhoneVerified(true);
        setIsNewUser(false);

        setStep(3);
      } else {
        setShowErr(true);
        setErr(
          "Kodu hatalı yazdınız. Lütfen tekrar yazın ve sonraki sayfaya tıklayın. 🤩"
        );
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error verifying phone number:", error);
      setShowErr(true);
      setErr("Telefon numarası doğrulama sırasında bir hata oluştu.");
    }
  };

  // Yeni Kullanıcı Kodu Doğrula
  const newUserCheckVerifyCode = async () => {
    const rawPhoneNumber = watch("phoneNumber").replace(/\s/g, "");
    const verifyCode = watch("verifyCode");

    if (!verifyCode) {
      setShowErr(true);
      setErr("Lütfen doğrulama kodunu giriniz.");
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.put(
        `${backendUrl}/user/tel-verify`,
        {
          phone_number: rawPhoneNumber,
          verifcation_code: verifyCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);

      if (response.data.data.status) {
        setIsPhoneVerified(true);
        setIsNewUser(false);
        setStep(3);
      } else {
        setShowErr(true);
        setErr(
          "Kodu hatalı yazdınız. Lütfen tekrar yazın ve sonraki sayfaya tıklayın. 🤩"
        );
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error verifying phone number:", error);
      setShowErr(true);
      setErr("Telefon numarası doğrulama sırasında bir hata oluştu.");
    }
  };

  // Doğrulama Kodu
  const verifyPhoneNumber = async (isNewUser) => {
    const rawPhoneNumber = watch("phoneNumber").replace(/\s/g, "");
    if (rawPhoneNumber.length < 10) {
      setShowErr(true);
      setErr("Lütfen geçerli bir telefon numarası girin.");
      return;
    }

    try {
      setIsLoading(true);
      setIsPhoneSendCodeLoading(true);
      await axios.post(`${backendUrl}/user/start-tel-verify`, {
        phone_number: rawPhoneNumber,
      });
      setIsPhoneSendCodeLoading(false);
      setIsLoading(false);
      setSendCode(true);

      if (isNewUser === false) {
        setStep(2);
      }
    } catch (error) {
      setIsPhoneSendCodeLoading(false);
      console.error("Error verifying phone number:", error);
      setShowErr(true);
      setErr(
        "Bu numara sistemimizde kayıtlı değil 😔. Üye olduktan sonra paket satın alma işlemine devam edebilirsin."
      );
    } finally {
      setIsLoading(false);
      setIsPhoneSendCodeLoading(false);
    }
  };

  const handleApplyDiscount = async () => {
    const formData = getValues();
    const discountCode = formData.discountCode;

    if (
      !formData.name ||
      !formData.surname ||
      !formData.email ||
      !formData.address ||
      !formData.city
    ) {
      setShowErr(true);
      setErr("Lütfen tüm zorunlu alanları doldurun.");
      return;
    }

    try {
      const response = await axios.get(
        `${backendUrl}/check-coupon-status?coupon_name=${discountCode}`
      );
      const responseCode = response.data.data;

      if (!responseCode.type) {
        setErr("Üzgünüz bu şekilde bir kupon yok 😔");
        setShowErr(true);
        return;
      }

      if (responseCode.packets) {
        const packetIds = responseCode.packets.map(
          (packet) => packet.packet_id
        );
        if (!packetIds.includes(packetInfos.id)) {
          setShowErr(true);
          setErr("Bu kupon bu paket için uygun değil 😔");
          return;
        }
      }

      if (!responseCode.useable) {
        setErr("Üzgünüz bu kupon aktif değil 😔");
        setShowErr(true);
        return;
      }

      const currentDate = new Date();
      const expireDate = new Date(responseCode.expire_date);
      if (expireDate < currentDate) {
        setErr("Üzgünüz kupon süresi geçti 😔");
        setShowErr(true);
        return;
      }

      setDiscountAmount({
        discount: responseCode.discount,
        type: responseCode.type,
        packets: responseCode.packets,
      });

      Swal.fire({
        title: "Başarılı",
        text: "İndirim kodunuz başarılı bir şekilde uygulandı fiyatları yeniden kontrol et 😉.",
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });

      setErr("");
      setShowErr(false);
    } catch (error) {
      console.error("Error applying discount:", error);
      setErr("İndirim kodu uygulanırken bir hata oluştu.");
      setShowErr(true);
    }
  };

  const checkUserPacketStatus = async (phoneNumber) => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${backendUrl}/check-user-packet-status?phone=${phoneNumber}&country_code=90`
      );
      const { current_level, packet_id, new_user } = response.data.data;
      setCurrentPacket(packet_id);
      setCurrentLevel(current_level);
      setIsNewUser(new_user);
      setIsLoading(false);

      if (new_user === true) {
        await signUpUser();
      } else {
        setShowErr(true);
        setErr("Bu numara sistemimizde zaten kayıtlı lütfen giriş yapınız.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching user packet status:", error);
    }
  };

  // Kullanıcı Kontrol
  const authControl = async () => {
    const phoneNumber = watch("phoneNumber");
    await checkUserPacketStatus(phoneNumber?.replace(/\s/g, ""));
  };

  const authUserControl = async () => {
    const phoneNumber = watch("phoneNumber").replace(/\s/g, "");

    try {
      setIsLoading(true);
      const response = await axios.post(`${backendUrl}/auth/control`, {
        phone_number: phoneNumber,
        country_code: "+90",
      });

      if (response?.data?.data?.Message === "verify-phone") {
        setStep(5);
        setIsVisiblePassword(false);
        await verifyPhoneNumber(true);
        return;
      } else {
        await verifyPhoneNumber(false);
        return;
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching user packet status:", error);
    }
  };

  const signUpUser = async () => {
    const rawPhoneNumber = watch("phoneNumber").replace(/\s/g, "");

    if (rawPhoneNumber.length < 10) {
      setShowErr(true);
      setErr("Lütfen geçerli bir telefon numarası girin.");
      return;
    }

    if (watch("password").length < 8) {
      setPasswordError("Şifre en az 8 karakter olmalıdır");
    }

    try {
      setIsLoading(true);

      await axios.post(`${backendUrl}/auth/signup`, {
        country_code: "+90",
        phone_number: rawPhoneNumber,
        password: watch("password"),
      });

      setIsLoading(false);
      await verifyPhoneNumber(true);
    } catch (error) {
      setIsLoading(false);
      console.error("Error signing up user:", error);
      setShowErr(true);
      setErr("Kullanıcı oluşturulurken bir hata oluştu.");
    }
  };

  const nextStep = async () => {
    if (isLoading) return;

    if (step === 1) {
      await authUserControl();

      if (!isChecked) {
        setShowErr(true);
        setErr("Lütfen Sözleşmeleri Onaylayın");
        return;
      }
    } else if (step === 2) {
      await checkVerifyCode();
    } else if (step === 3) {
      const nameInput = document.querySelector('input[name="name"]');
      const surnameInput = document.querySelector('input[name="surname"]');
      const addressInput = document.querySelector('input[name="address"]');
      const cityInput = document.querySelector('select[name="city"]');

      const newErrors = {};

      if (!nameInput?.value?.trim()) newErrors.name = "Ad alanı zorunludur";
      if (!surnameInput?.value?.trim())
        newErrors.surname = "Soyad alanı zorunludur";
      if (!addressInput?.value?.trim())
        newErrors.address = "Adres alanı zorunludur";
      if (!cityInput?.value?.trim()) newErrors.city = "Şehir seçimi zorunludur";

      if (nameInput?.value) setValue("name", nameInput.value);
      if (surnameInput?.value) setValue("surname", surnameInput.value);
      if (addressInput?.value) setValue("address", addressInput.value);
      if (cityInput?.value) setValue("city", cityInput.value);

      if (Object.keys(newErrors).length > 0) {
        setErr(newErrors);
        return;
      }

      setErr({});
      if (packetInfos.id === 2) {
        await handleBuy(getValues());
      } else {
        setStep(step + 1);
      }
    }
  };

  const formatPrice = (price) => {
    return price.toLocaleString("tr-TR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const applyDiscount = (price) => {
    const isDiscountApplicable =
      discount &&
      discount.packets &&
      discount.packets.some(
        (discountPacket) => discountPacket.packet_id === packetInfos.id
      );

    if (!isDiscountApplicable || discount.discount <= 0) return price;

    let discountedPrice = price;
    if (discount.type === "yuzde") {
      discountedPrice = price - price * (discount.discount / 100);
    } else if (discount.type === "miktar") {
      discountedPrice = price - discount.discount;
    }

    return discountedPrice > 0 ? discountedPrice : 0;
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const finalPrice = applyDiscount(packetInfos?.price);

  const closeModal = () => {
    setOpenModal(false);
    setIsChecked(false);
    setIsLoading(false);
    setIsPhoneVerified(false);
    setIsPhoneSendCodeLoading(false);
    setSendCode(false);
    setErr("");
    setShowErr(false);
    setPasswordError("");
    setStep(1);
  };

  const onSubmit = async (data) => {
    if (step === 4) {
      await handleBuy(data);
    } else {
      await nextStep();
    }
  };

  const handleInputChange = (field) => {
    setErr((prev) => ({ ...prev, [field]: null }));
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;

    // Şifre validasyonu
    if (!value) {
      setPasswordError("Şifre zorunludur");
    } else if (value.length < 8) {
      setPasswordError("Şifre en az 8 karakter olmalıdır");
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.,:-])[A-Za-z\d@$!%*?&.,:-]{8,}$/.test(
        value
      )
    ) {
      setPasswordError(
        "Şifre en az bir büyük harf, bir küçük harf, bir rakam ve bir özel karakter içermelidir"
      );
    } else {
      setPasswordError(""); // Hata yoksa temizle
    }

    setValue("password", value); // Update the password value in form
  };

  useEffect(() => {
    let timer;
    if (showErr) {
      timer = setTimeout(() => {
        setShowErr(false);
      }, 6000);
    }
    return () => clearTimeout(timer);
  }, [showErr]);

  useEffect(() => {
    const inputArea = document.querySelector(".inputArea");
    if (inputArea) {
      inputArea.scrollTop = 0;
    }
  }, []);

  return (
    openModal && (
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-[1000] overflow-hidden`}
      >
        <div className="bg-[#ff501c] xl:bg-transparent flex flex-col rounded-2xl xl:rounded-none xl:flex-row xl:w-[55vw] xl:h-[75vh] relative w-[90vw] max-h-[80vh] overflow-y-scroll xl:overflow-hidden">
          <div className="leftArea bg-[#ff501c] w-full xl:w-1/2 rounded-2xl relative px-8 py-16 flex flex-col justify-between">
            <div className="flex items-center justify-center absolute top-6 xl:top-8 right-12 xl:right-6">
              <div className="flex space-x-1">
                {[1, 2, 3, 4].map((stepNum) => (
                  <p
                    key={stepNum}
                    className={`xl:w-[10px] xl:h-[10px] w-[8px] h-[8px] ${
                      step === stepNum ? "bg-white" : "bg-[#df7657]"
                    } rounded-full`}
                  />
                ))}
              </div>
            </div>

            <div className="packetInfos text-white flex flex-col items-center justify-center">
              <h1 className="text-xl xl:text-3xl font-bold">Paket Adı:</h1>
              <h1 className="text-xl xl:text-3xl font-bold my-3">
                {packetInfos.title}
              </h1>
              {discount !== "" && (
                <h1 className="relative inline-block text-base font-light">
                  <p className="text-base font-medium">
                    {formatPrice(packetInfos?.price)} ₺
                  </p>
                  <p className="absolute left-0 right-0 top-1/2 h-[2px] bg-white transform -rotate-6 z-0"></p>
                </h1>
              )}
              <div className="flex flex-row items-center justify-center">
                <h1 className="text-base xl:text-xl font-medium">
                  {formatPrice(finalPrice)} ₺
                </h1>
              </div>
            </div>

            <div className=" middleText text-white flex flex-col items-center justify-center gap-3">
              <h1 className="text-xl xl:text-3xl font-bold">
                {step === 1
                  ? "hadi başlayalım"
                  : step === 2
                  ? "özlem ve hasret"
                  : step === 3
                  ? "Fatura beni çok yoruyor"
                  : "Son adımmmm"}
              </h1>
              <h1 className="text-sm xl:text-lg font-medium">
                {step === 1
                  ? "ben çok heyecanlıyım ya sen ?"
                  : step === 2
                  ? "sen ve ben bence çok iyiyiz"
                  : step === 3
                  ? "iyi ki muhasebeci değilim"
                  : "yoksa sende mi sendeledin ben gibi"}
              </h1>
            </div>

            <Link target="_blank" to={"https://wa.me/+908503029072"}>
              <div className="bottomText hidden xl:flex text-white flex-col items-center justify-center">
                <h1 className="text-sm font-medium mb-3">
                  sorun yaşarsan iletişime geç
                </h1>
              </div>
            </Link>
          </div>

          <div className="w-full xl:w-1/2 bg-white border border-[#a65195] rounded-2xl px-8 py-16">
            <button
              type="button"
              onClick={closeModal}
              className="closeArea bg-orange-500 h-6 flex flex-row items-center justify-center w-6 text-sm text-white font-bold absolute top-4 right-4 rounded-full"
            >
              X
            </button>

            <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
              {step === 1 && (
                <div className="w-full flex flex-col items-start justify-center h-full gap-4">
                  <div>
                    <h1 className="text-black xl:text-3xl text-xl font-bold">
                      İlk olarak hesabına giriş
                      <p className="text-orange-600 xl:text-3xl text-xl text-left font-bold">
                        {" "}
                        yapalım
                      </p>
                    </h1>
                  </div>

                  <div className="w-full flex flex-col gap-5">
                    <h1 className="text-sm xl:text-base font-bold text-black text-left">
                      Telefon Numaranızı Girin:
                    </h1>

                    <div className="w-full flex items-center bg-[#fffbfa] border border-gray-400 rounded-xl overflow-hidden">
                      <div className="flex items-center justify-center bg-[#fffbfa] text-gray-600 text-sm px-4 py-3 rounded-xl border-r border-gray-400 font-normal">
                        +90
                      </div>
                      <input
                        {...register("phoneNumber", {
                          required: "Telefon numarası zorunludur",
                          pattern: {
                            value: /^[0-9\s]{13}$/,
                            message: "Geçerli bir telefon numarası giriniz",
                          },
                        })}
                        onChange={handlePhoneChange}
                        maxLength={13}
                        type="tel"
                        placeholder="555 555 5555"
                        className="flex-1 px-4 py-3 text-gray-600 text-sm outline-none bg-[#fffbfa] placeholder-gray-400 font-normal"
                      />
                    </div>

                    <div className="flex items-center justify-start gap-3">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className="w-8 h-8 text-red-500 checked:bg-orange-500 rounded focus:ring-red-500 border-gray-300"
                      />

                      <p className="text-gray-600 text-sm font-normal">
                        Mesafeli Satış Sözleşmesi, İptal/İade Sözleşmesi ve
                        Kişisel Satış Sözleşmesini onaylamak için tıklayın.
                      </p>
                    </div>

                    {(errors?.phoneNumber || showErr) && (
                      <p className="text-red-500 font-bold text-sm mt-1">
                        {errors?.phoneNumber?.message || err}
                      </p>
                    )}
                  </div>

                  <div className="w-full flex flex-col items-center justify-center gap-4">
                    <button
                      type="button"
                      onClick={nextStep}
                      disabled={
                        !isChecked || isPhoneSendCodeLoading || sendCode
                      }
                      className="w-full py-2 px-12 bg-black text-white text-base xl:text-lg font-semibold rounded-lg"
                      style={{
                        opacity:
                          !isChecked || isPhoneSendCodeLoading || sendCode
                            ? 0.5
                            : 1,
                        cursor:
                          !isChecked || isPhoneSendCodeLoading || sendCode
                            ? "not-allowed"
                            : "pointer",
                      }}
                    >
                      Hesabına Giriş Yap
                    </button>

                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          setStep(5);
                          setShowErr(false);
                          setErr("");
                        }}
                        className="text-sm text-center font-medium"
                      >
                        Hesabınız yok mu? Kayıt Olun
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {step === 2 && (
                <div className="w-full flex flex-col items-start justify-center h-full gap-4 mt-4">
                  <div className="w-full">
                    <div className="labelArea w-full">
                      <h1 className="text-sm xl:text-base mb-3 font-bold text-black text-left">
                        Telefonunuza gelen doğrulama kodunu giriniz:
                      </h1>
                    </div>
                    <div className="flex items-center bg-[#fffbfa] border border-gray-400 rounded-xl overflow-hidden w-full">
                      <input
                        {...register("verifyCode", {
                          required: "Doğrulama kodu zorunludur",
                        })}
                        type="text"
                        placeholder="Doğrulama Kodu"
                        className="flex-1 px-4 py-3 text-gray-600 text-sm outline-none bg-[#fffbfa] placeholder-gray-400 font-normal"
                      />
                    </div>
                  </div>

                  {(errors?.verifyCode || showErr) && (
                    <p className="text-red-500 font-bold text-sm">
                      {errors?.verifyCode?.message || err}
                    </p>
                  )}

                  <button
                    type="button"
                    onClick={nextStep}
                    className="w-full py-2 px-12 bg-black text-white text-base xl:text-lg font-semibold rounded-lg"
                  >
                    Sonraki Sayfa
                  </button>
                </div>
              )}

              {step === 3 && (
                <div className="w-full flex flex-col items-start justify-between h-full gap-4">
                  <div className="w-full flex flex-col gap-4">
                    <div className="w-full">
                      <h1 className="text-sm xl:text-base mb-3 font-bold text-black text-left">
                        Adınız *
                      </h1>
                      <input
                        {...register("name", {
                          required: "Ad alanı zorunludur",
                        })}
                        type="text"
                        placeholder="Ad (zorunlu)"
                        onChange={() => handleInputChange("name")}
                        className="w-full px-4 py-3 text-black font-normal outline-none bg-[#fffbfa] border border-gray-400 rounded-xl"
                      />
                      {err.name && (
                        <p className="text-red-500 font-bold text-sm mt-1">
                          {err.name}
                        </p>
                      )}
                    </div>

                    <div className="w-full">
                      <h1 className="text-sm xl:text-base mb-3 font-bold text-black text-left">
                        Soyadınız *
                      </h1>
                      <input
                        {...register("surname", {
                          required: "Soyad doldurulması zorunludur",
                        })}
                        type="text"
                        placeholder="Soyad (zorunlu)"
                        onChange={() => handleInputChange("surname")}
                        className="w-full px-4 py-3 text-black font-normal text-sm outline-none bg-[#fffbfa] border border-gray-400 rounded-xl"
                      />
                      {err.surname && (
                        <p className="text-red-500 font-bold text-sm mt-1">
                          {err.surname}
                        </p>
                      )}
                    </div>

                    <div className="w-full">
                      <h1 className="text-sm xl:text-base mb-3 font-bold text-black text-left">
                        Fatura Adres *
                      </h1>
                      <input
                        {...register("address", {
                          required: "Adres doldurulması zorunludur",
                        })}
                        type="text"
                        placeholder="Adres (zorunlu)"
                        onChange={() => handleInputChange("address")}
                        className="w-full px-4 py-3 text-black font-normal outline-none bg-[#fffbfa] border border-gray-400 rounded-xl"
                      />
                      {err.address && (
                        <p className="text-red-500 font-bold text-sm mt-1">
                          {err.address}
                        </p>
                      )}
                    </div>

                    <div className="w-full">
                      <h1 className="text-sm xl:text-base mb-3 font-bold text-black text-left">
                        Şehir *
                      </h1>
                      <select
                        {...register("city", {
                          required: "Şehir seçimi zorunludur",
                        })}
                        onChange={() => handleInputChange("city")}
                        className="w-full px-4 py-3 text-black font-normal outline-none bg-[#fffbfa] border border-gray-400 rounded-xl"
                      >
                        <option value="">Şehir Seçiniz</option>
                        {Object.entries(cities).map(([key, city]) => (
                          <option key={key} value={city}>
                            {city}
                          </option>
                        ))}
                      </select>
                      {err.city && (
                        <p className="text-red-500 font-bold text-sm mt-1">
                          {err.city}
                        </p>
                      )}
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={nextStep}
                    className="w-full py-2 px-12 bg-black text-white text-base xl:text-lg font-semibold rounded-lg"
                  >
                    {packetInfos.id === 2 ? "Ödeme Yap" : "Sonraki Sayfa"}
                  </button>
                </div>
              )}

              {step === 4 && (
                <div className="w-full flex flex-col items-center justify-center h-full gap-4 mt-4">
                  <div className="w-full">
                    <div className="labelArea w-full">
                      <h1 className="text-base mb-3 font-bold text-black text-left">
                        Varsa lütfen giriniz
                      </h1>
                    </div>

                    <div className="flex items-center bg-[#fffbfa] border border-gray-400 rounded-xl overflow-hidden w-full">
                      <input
                        {...register("discountCode")}
                        type="text"
                        placeholder="Kodu giriniz"
                        className="px-4 py-3 text-gray-600 text-sm outline-none bg-[#fffbfa] placeholder-gray-400 font-normal w-2/3"
                      />
                      <button
                        type="button"
                        onClick={handleApplyDiscount}
                        className="flex-1 items-center justify-center bg-[#fffbfa] text-gray-600 text-sm px-4 py-3 rounded-xl border-l w-1/3 border-gray-400 font-normal"
                      >
                        Uygula
                      </button>
                    </div>

                    {showErr && (
                      <p className="text-red-500 font-bold text-sm mt-4">
                        {err}
                      </p>
                    )}
                  </div>

                  <button
                    type="submit"
                    className="w-full py-2 px-12 bg-black text-white text-base xl:text-lg font-semibold rounded-lg"
                  >
                    Ödemeye Geç
                  </button>
                </div>
              )}

              {step === 5 && (
                <div className="w-full flex flex-col items-center justify-between h-full gap-5">
                  <div>
                    <h1 className="text-black xl:text-3xl text-xl font-bold">
                      Hemen kayıt ol hızlıca
                      <p className="text-orange-600 xl:text-3xl text-xl text-left font-bold">
                        {" "}
                        başlayalım
                      </p>
                    </h1>
                  </div>

                  <div className="w-full flex flex-col gap-4">
                    <div className="w-full">
                      <div className="labelArea w-full">
                        <h1 className="text-base mb-3 font-bold text-black text-left">
                          Telefon Numaranızı Girin:
                        </h1>
                      </div>

                      <div className="w-full flex items-center bg-[#fffbfa] border border-gray-400 rounded-xl overflow-hidden">
                        <div className="flex items-center justify-center bg-[#fffbfa] text-gray-600 text-sm px-4 py-3 rounded-xl border-r border-gray-400 font-normal">
                          +90
                        </div>
                        <input
                          {...register("phoneNumber", {
                            required: "Telefon numarası zorunludur",
                            pattern: {
                              value: /^[0-9\s]{13}$/,
                              message: "Geçerli bir telefon numarası giriniz",
                            },
                          })}
                          onChange={handlePhoneChange}
                          maxLength={13}
                          type="tel"
                          placeholder="555 555 5555"
                          className="flex-1 px-4 py-3 text-gray-600 text-sm outline-none bg-[#fffbfa] placeholder-gray-400 font-normal"
                        />
                      </div>
                    </div>

                    {isVisiblePassword && (
                      <div>
                        <div className="w-full">
                          <div className="labelArea w-full">
                            <h1 className="text-base mb-3 font-bold text-black text-left">
                              Şifrenizi Belirleyin:
                            </h1>
                          </div>
                        </div>

                        <div className="w-full flex flex-row justify-between items-center bg-[#fffbfa] border border-gray-400 rounded-xl overflow-hidden">
                          <input
                            {...register("password", {
                              required: "Şifre zorunludur",
                              minLength: {
                                value: 8,
                                message: "Şifre en az 8 karakter olmalıdır",
                              },
                              pattern: {
                                value:
                                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.,:-])[A-Za-z\d@$!%*?&.,:-]{8,}$/,

                                message:
                                  "Şifre en az bir büyük harf, bir küçük harf, bir rakam ve bir özel karakter içermelidir",
                              },
                            })}
                            type={showPassword ? "text" : "password"}
                            placeholder="Şifre"
                            className="w-full px-4 py-3 text-gray-600 text-sm outline-none bg-[#fffbfa] placeholder-gray-400 font-normal"
                            onChange={handlePasswordChange}
                          />

                          <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="w-12 px-3 py-2 items-center justify-center"
                            aria-label={
                              showPassword ? "Şifreyi gizle" : "Şifreyi göster"
                            }
                          >
                            {showPassword ? (
                              <AiFillEyeInvisible size={24} />
                            ) : (
                              <AiFillEye size={24} />
                            )}
                          </button>
                        </div>
                      </div>
                    )}

                    {passwordError && (
                      <p className="text-red-500 font-bold text-sm mt-1">
                        {passwordError}
                      </p>
                    )}

                    {sendCode && (
                      <div className="w-full">
                        <div className="labelArea w-full">
                          <h1 className="text-sm xl:text-base mb-3 font-bold text-black text-left">
                            Telefonunuza gelen doğrulama kodunu giriniz:
                          </h1>
                        </div>

                        <div className="flex items-center bg-[#fffbfa] border border-gray-400 rounded-xl overflow-hidden w-full">
                          <input
                            {...register("verifyCode", {
                              required: "Doğrulama kodu zorunludur",
                            })}
                            type="text"
                            placeholder="Doğrulama Kodu"
                            className="flex-1 px-4 py-3 text-gray-600 text-sm outline-none bg-[#fffbfa] placeholder-gray-400 font-normal"
                          />
                        </div>
                      </div>
                    )}

                    {(errors?.phoneNumber || showErr || !isNewUser) && (
                      <p className="text-red-500 font-bold text-sm mt-1">
                        {errors?.phoneNumber?.message || err}
                      </p>
                    )}
                  </div>

                  <div className="w-full flex flex-col items-center justify-center gap-4">
                    <CustomButton
                      disabled={
                        passwordError ||
                        !watch("phoneNumber") ||
                        (isVisiblePassword && !watch("password"))
                      }
                      onClick={
                        sendCode
                          ? newUserCheckVerifyCode
                          : isNewUser
                          ? signUpUser
                          : authControl
                      }
                      text={sendCode ? "Doğrula" : "Hesabını Oluştur"}
                      loading={isLoading}
                    />

                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          setStep(1);
                          setShowErr(false);
                          setErr("");
                        }}
                        className="text-sm text-center font-medium"
                      >
                        Hesabınız var mı? Giriş Yapın
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </form>

            <Link target="_blank" to={"https://wa.me/+908503029072"}>
              <div className="bottomText block xl:hidden text-black flex-col items-center justify-center h-1/3">
                <h1 className="text-sm text-center font-medium mt-8">
                  sorun yaşarsan iletişime geç
                </h1>
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  );
}

export default PaymentModal;
