import React, { useState, useEffect } from "react";
import playStore from "../images/get-it-on-google-play.png";
import check from "../images/check.png";
import appStore from "../images/get-it-on-app-store.svg";
import axios from "axios";
import { Link } from "react-router-dom";
import PacketBox from "./packetBox";
import Swal from "sweetalert2";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

function SecondSection({
  setOpenModal,
  setPacketInfos,
  packets,
  currentPacket,
  discountAmount,
  handleBuy,
  currentLevel,
}) {
  return (
    <div className="bg-[#fffbfa] py-8 w-full px-4 flex flex-col items-center justify-center">
      {/* Store buttons */}
      <div className="marketArea w-full flex items-center justify-center mb-12">
        <Link
          to="https://play.google.com/store/apps/details?id=com.tovivu.client.app&hl=tr"
          target="_blank"
        >
          <img
            src={playStore}
            className="w-36 lg:w-[13vw] h-9 lg:h-[57px] rounded-lg"
            alt="Play Store"
          />
        </Link>
        <Link
          to="https://apps.apple.com/tr/app/tovivu/id6477754512"
          target="_blank"
          className="ml-8"
        >
          <img
            src={appStore}
            className="w-36 lg:w-[13vw] h-36 lg:h-[13vw] ml-4 lg:ml-8"
            alt="App Store"
          />
        </Link>
      </div>

      {/* Text content */}
      <div className="featureArea flex flex-row items-center justify-center my-8">
        <div>
          <h1 className="text-4xl lg:text-6xl font-bold text-[#f26424] flex flex-col">
            <div>
              <span className="text-[#f9cc00]">t</span>
              <span className="text-black">o</span>
              <span className="text-black">v</span>
              <span className="text-[#f9cc00]">i</span>
              <span className="text-black">vu</span>{" "}
              <span className="text-[#f26424]">dünyasını</span>
            </div>
            <span className="text-[#f26424] mt-5">keşfet:</span>
          </h1>
          <div className="features">
            <ul className="mt-6 text-sm lg:text-lg text-gray-600 font-light flex flex-col justify-start">
              <li className="flex my-2 items-center justify-start">
                <img src={check} className="w-[34px] h-8 mr-3" alt="check1" />
                Çalma listesi oluştur ve özelleştir
              </li>
              <li className="flex my-2 items-center justify-start">
                <img src={check} className="w-[34px] h-8 mr-3" alt="check2" />
                Her yerde erişim
              </li>
              <li className="flex my-2 items-center justify-start">
                <img src={check} className="w-[34px] h-8 mr-3" alt="check3" />
                Ortalama 3 dakikada bir konu dinle - izle
              </li>
              <li className="flex my-2 items-center justify-start">
                <img src={check} className="w-[34px] h-8 mr-3" alt="check4" />
                Aile boyu erişim
              </li>
              <li className="flex my-2 items-center justify-start">
                <img src={check} className="w-[34px] h-8 mr-3" alt="check5" />
                Bir hesaptan YKS - KPSS - LGS’ye erişim
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-14">
        {packets.map((packet) => (
          <PacketBox
            setPacketInfos={setPacketInfos}
            currentPacket={currentPacket}
            discount={discountAmount}
            handleBuy={handleBuy}
            current={currentLevel}
            key={packet.id}
            packet={packet}
            setOpenModal={setOpenModal}
          />
        ))}
      </div>
    </div>
  );
}

export default SecondSection;
