import React from "react";
import Spinner from "./spinner";

const CustomButton = ({
  loading = false,
  disabled = false,
  onClick,
  text = "Button",
}) => {
  return (
    <div
      onClick={disabled || loading ? undefined : onClick}
      className="w-full flex flex-row items-center justify-center gap-5 py-2 bg-black text-white text-base xl:text-lg font-semibold rounded-lg"
      style={{
        opacity: disabled || loading ? 0.5 : 1,
        cursor: disabled || loading ? "not-allowed" : "pointer",
      }}
    >
      {loading && <Spinner width="20px" height="20px" />}
      <span>{loading ? "Yükleniyor" : text}</span>
    </div>
  );
};

export default CustomButton;
