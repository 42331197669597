import React from "react";

const Spinner = ({ width = "40px", height = "40px" }) => {
  return (
    <div>
      <div
        style={{
          width: width,
          height: height,
          borderWidth: "8px",
          border: "4px solid rgba(0, 0, 0, 0.1)",
          borderTop: "4px solid #FF501B",
          borderRadius: "50%",
          animation: "spin 1s linear infinite",
        }}
      ></div>
      <style>
        {`
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `}
      </style>
    </div>
  );
};

export default Spinner;
